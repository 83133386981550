import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import media from '../common/mediaQuery'
import { Type } from '../common/type'

// Sort and display the different slice options
const PostSlices = ({ slices }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch(slice.type) {
        case 'product': return (
          <li>
            {/* <Link to={`/editorial/${url}`}></Link> */}
            <Link to={`/products/${ slice.primary.selected_product.handle }`}>
              <ImageWrapper>
                <img src={ slice.primary.selected_product.image.src } alt="" />
              </ImageWrapper>
              <Type as="h3" bold>{ slice.primary.selected_product.title } ↗</Type>
              <Type>{ slice.primary.selected_product.product_type }</Type>
            </Link>
          </li>
        )
        default: return
      }
    })();
    return res;
  })
}

const SelectedProducts = ({ productData }) => {
  return (
    <PostSlices slices={ productData.body } />
  );
}

class CollectionList extends Component {
  
  render() {
    const { collections } = this.props;

    return ( 
      <>
        {collections.map((item, index) => (
          <ListWrapper key={ index }>
            <Meta>
              <Type bold>{ item.collection.title[0].text }</Type>
            </Meta>
            {/* <Products>
              <SelectedProducts productData={ item.collection } />
            </Products> */}
          </ListWrapper>
        ))}
      </>
    );
  }
}
 
export default CollectionList;

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  padding: 0 25px;
  width: 100%;

  ${media.tablet`
    padding: 0 0 0 30px;
    margin-top: 35px;
  `}
`;

const Meta = styled.div`
  align-items: flex-start;
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${media.tablet`
    flex: 0 0 50%;
  `}

  ${media.tabletLarge`
    flex: 0 0 27.3%;
    padding-right: 10px;
  `}
`;

const Products = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;

  ${media.tabletLarge`
    flex: 0 0 72.7%;
    margin-top: 0;
  `}

  li {
    margin-top: 30px;
    width: 100%;

    ${media.tablet`
      flex: 0 0 48.5%;
      padding-right: 30px;
      margin-bottom: 30px;
      margin-top: 0;
    `}

    &:first-of-type {
      margin-top: 0;
    }
  }

  h3 {
    margin-top: 20px;
  }
`;

const ImageWrapper = styled.div`
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  width: 100%;

  img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 1px;
    width: 100%;
  }
`;
