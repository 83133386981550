import React from 'react';
import { Helmet } from "react-helmet"
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components';

import { theme } from '../common/global';

import Layout from '../components/layout';
import ContextConsumer from '../components/context'
import CollectionList from '../components/CollectionList';


// Sort and display the different slice options
const PostSlices = ({ slices }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch(slice.type) {
        case 'collections': return (
          <CollectionList key={ index } collections={ slice.fields } />
        )
        default: return
      }
    })();
    return res;
  })
}

const LandingContent = ({ landingData }) => {
  return (
    <PostSlices slices={ landingData.body } />
  );
}

export default ({ data }) => {
  // Required check for no data being returned
  const doc = data.prismic.allCollection_landings.edges.slice(0,1).pop();
  if (!doc) return null;

  return (
    <>
      <Layout>
        <Helmet>
          <title>Elkin Editions | Products</title>
          <meta name="description" content="Elkin Editions is an independent video production studio based in Lower Manhattan." />
          <meta property="og:image" content="../../share-image.jpg"></meta>
        </Helmet>
        {/* <ContextConsumer>
          {({ contextData }) => (
            <Wrapper isInverted={process.browser && localStorage.getItem('page_color')}>
              <LandingContent landingData={ doc.node } />
            </Wrapper>
          )}
        </ContextConsumer> */}
      </Layout>
    </>
  );
}

const Wrapper = styled.div`
  background-color: ${theme.colors.black};
  display: flex;
  flex: 0 1 100%;
  flex-direction: row;
  flex-wrap: wrap;
  transition: all ${theme.speed.fast} ease-in-out;

  ${props => props.isInverted === 'true' && css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};

    a {
      color: ${theme.colors.black};
    }
  `}
`;

export const query = graphql`
{
  prismic {
    allCollection_landings {
      edges {
        node {
          _meta {
            id
          }
          title
          body {
            ... on PRISMIC_Collection_landingBodyCollections {
              type
              label
              fields {
                collection {
                  ... on PRISMIC_Collection {
                    title
                    _linkType
                    body {
                      ... on PRISMIC_CollectionBodyProduct {
                        type
                        label
                        primary {
                          selected_product
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`